.sidebar {
    border-right: '1px solid rgb(236, 236, 236)';
    padding: '2px 16px 16px';
}

.sidebarMenu {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 10px !important;
    font-size: 16px !important;
    align-items: center !important;
    font-weight: 600 !important;
    user-select: none !important;
    color: rgb(47, 47, 47) !important;
    border-radius: 4px !important;
    width: 100% !important;
}

.navLinks {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    color: rgb(80, 80, 80);
    padding: 10px 12px;
    border-radius: 4px;
    font-weight: 500;
}

.navLinkActive {
    background-color: rgb(232, 225, 250);
    color: rgb(77, 13, 245);
}

.navLinkActive .navIcons {
    color: rgb(77, 13, 245);
}

.navLinks:hover {
    background-color: rgb(249, 249, 249);
    color: rgb(0, 0, 0);
}

.navIcons {
    margin-right: 12px;
    color: rgb(142, 142, 142);
}
.justifyStart {
    justify-content: start;
}

.justifyCenter {
    justify-content: center;
}

.justifySpaceBetween {
    justify-content: space-between;
}

.justifyFlexEnd {
    justify-content: flex-end;
}

.displayFlex {
    display: flex !important;
}

.displayBlock {
    display: block !important;
}

.flexDirectionColumn {
    flex-direction: column !important;
}

.alignContentCenter {
    align-content: center !important;
}

.alignItemsCenter {
    align-items: center !important;
}

.alignItemsStart {
    align-items: flex-start !important;
}

.mt05 {
    margin-top: 0.5rem !important;
}

.mt0 {
    margin-top: 0 !important;
}

.mt1 {
    margin-top: 1rem !important;
}

.mt1_5 {
    margin-top: 1.5rem !important;
}

.mt9 {
    margin-top: 9px !important;
}

.mt1p {
    margin-top: 1px !important;
}

.mt5p {
    margin-top: 5px !important;
}

.mt8p {
    margin-top: 8px !important;
}

.mt12 {
    margin-top: 12px !important;
}

.mt2 {
    margin-top: 2rem !important;
}

.mt3 {
    margin-top: 3rem !important;
}

.mt4 {
    margin-top: 4rem !important;
}

.mt5 {
    margin-top: 5rem !important;
}

.ml0 {
    margin-left: 0 !important;
}

.ml05 {
    margin-left: 0.5rem !important;
}

.ml1 {
    margin-left: 1rem !important;
}

.ml2 {
    margin-left: 2rem !important;
}

.m4_5 {
    margin-left: 4.5rem !important;
}

.ml5p {
    margin-left: 5px !important;
}

.ml10p {
    margin-left: 10px !important;
}

.my5px {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.my15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.mt16px {
    margin-top: 16px !important;
}

.mx0px {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.mr0 {
    margin-right: 0px !important;
}

.mr05 {
    margin-right: 0.5rem !important;
}

.mr1 {
    margin-right: 1rem !important;
}

.mr2 {
    margin-right: 2rem !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mb05 {
    margin-bottom: 0.5rem !important;
}

.mb1 {
    margin-bottom: 1rem !important;
}

.mb1_5 {
    margin-bottom: 1.5rem !important;
}

.mb2 {
    margin-bottom: 2rem !important;
}

.m5x34x2x34 {
    margin: 5rem 34rem 2rem 34rem !important;
}

.textAlignCenter {
    text-align: center !important;
}

.textAlignRight {
    text-align: right;
}

.textAlignLeft {
    text-align: left !important;
}

.textDecorationNone {
    text-decoration: none !important;
}

.mrAuto {
    margin-right: auto !important;
}

.mlAuto {
    margin-left: auto !important;
}

.mr10 {
    margin-right: 10px !important;
}

.displayBlock {
    display: block;
}

.pr1 {
    padding-right: 1rem !important;
}

.pr2 {
    padding-right: 2rem !important;
}

.py05 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.minHeight40 {
    min-height: 40px !important;
}

.minHeight70 {
    min-height: 70px !important;
}

.minHeight400 {
    min-height: 400px !important;
}

.minHeight500 {
    min-height: 500px !important;
}


.colorPrimary {
    color: rgb(20, 0, 110) !important;
}

.colorBlack {
    color: rgb(0, 0, 0) !important;
}

.colorGrey {
    color: grey !important;
}

.colorWhite {
    color: #fff !important;
}

.pb1 {
    padding-bottom: 1rem !important;
}

.pb1_5 {
    padding-bottom: 1.5rem !important;
}

.pb2 {
    padding-bottom: 2rem !important;
}

.pb3 {
    padding-bottom: 3rem !important;
}

.border1 {
    border: 1px solid !important;
}

.border3 {
    border: 3px solid !important;
}

.borderNone {
    border: none !important;
}

.borderBottomGrey {
    border-bottom: 1px solid rgb(236, 236, 236);
}

.borderTopGrey {
    border-top: 5px solid rgb(212, 212, 212);
}

.height35 {
    height: 35px;
}

.height51 {
    height: 51px;
}

.fontWeightBold {
    font-weight: bold !important;
}

.fontWeight400 {
    font-weight: 400 !important;
}

.fontWeight500 {
    font-weight: 500 !important;
}

.fontWeight600 {
    font-weight: 600 !important;
}

.fontWeight700 {
    font-weight: 700 !important;
}

.fontSize12 {
    font-size: 12px !important;
}

.fontSize14 {
    font-size: 14px !important;
}

.fontSize1rem {
    font-size: 1rem !important;
}

.fontSize18 {
    font-size: 18px !important;
}

.fontSize22 {
    font-size: 1.375rem !important;
}

.fontSize24 {
    font-size: 24px !important;
}

.padding1 {
    padding: 1rem !important;
}

.padding05 {
    padding: 0.5rem !important;
}

.padding20 {
    padding: 20px !important;
}

.padding2x10 {
    padding: 2px 10px !important;
}

.paddingTop80 {
    padding-top: 80px !important;
}

.paddingTop10 {
    padding-top: 10px !important;
}

.width50 {
    width: 50% !important;
}

.width95 {
    width: 95% !important;
}

.width100 {
    width: 100% !important;
}

.minWidth50 {
    min-width: 50px !important;
}

.minWidth70 {
    min-width: 70px !important;
}

.minWidth300 {
    min-width: 300px !important;
}

.maxWidth300 {
    max-width: 300px !important;
}

.maxWidth220 {
    max-width: 220px !important;
}

.borderBottom2 {
    border-bottom: 2px solid !important;
}

.borderColorGrey {
    border-color: rgb(226, 226, 226) !important;
}

.borderColorGrey2 {
    border-color: rgb(226, 226, 226) !important;
}

.borderColorGrey3 {
    border-color: rgb(142, 142, 142) !important;
}

.borderColorBlue {
    border-color: rgb(48, 15, 195) !important;
}

.borderColorGreen {
    border-color: rgb(121, 209, 151) !important;
}

.borderRadius4 {
    border-radius: 4px;
}

.borderRadius8 {
    border-radius: 8px;
}

.tipBackgroundWarning {
    background-color: rgb(253, 245, 225);
}

.tipColorWarning {
    color: rgb(242, 178, 15) !important;
}

.padding12x16 {
    padding: 12px 16px !important;
}

.padding12x24 {
    padding: 12px 24px !important;
}

.tipBackgroundSuccess {
    background-color: rgb(242, 251, 245);
}

.tipBackgroundPurple {
    background-color: rgb(195, 177, 244);
}

.tipColorSuccess {
    color: rgb(84, 171, 114) !important;
}

.overflowVisible {
    overflow: visible !important;
}

.flexWrap {
    flex-wrap: wrap;
}

.whitespaceBreakSpace {
    white-space: break-spaces;
}

.textTransformNone {
    text-transform: none !important;
}

.textTrasformCapital {
    text-transform: capitalize !important;
}

.flex1 {
    flex: 1 !important;
}

.backgroundWhite {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundGrey {
    background-color: rgb(212, 212, 212) !important;
}

.backgroundLightGrey {
    background-color: rgb(249, 249, 249) !important;
}

.backgroundGradient {
    background-image: linear-gradient(-60deg, rgb(103, 50, 241) 0%, rgb(55, 18, 152) 100%) !important;
}

.backgroundPrimary {
    background-color: rgb(20, 0, 110) !important;
}

.backgroundBlue {
    background-color: rgb(48, 15, 195) !important;
}

.backgroundLightBlue {
    background-color: rgb(230, 248, 236) !important;
}


.borderTopLeftRound {
    border-top-left-radius: 5px !important;
}

.borderTopRightRound {
    border-top-right-radius: 5px !important;
}

.borderDashed {
    border: 3px dashed rgb(226, 226, 226) !important;
}

.grade_A_textColor {
    color: rgb(0, 128, 0) !important;
}

.grade_B_textColor {
    color: rgb(125, 135, 13) !important;
}

.grade_C_textColor {
    color: rgb(215, 159, 18) !important;
}

.grade_D_textColor {
    color: rgb(242, 163, 15) !important;
}

.grade_E_textColor {
    color: rgb(255, 69, 0) !important;
}

.grade_F_textColor {
    color: rgb(204, 84, 189) !important;
}

.twoColumnLayout45x45 {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 45% 45%;
}

.twoColumnLayout15x85 {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 15% 85%;
}

.twoColumnLayout85x15 {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 85% 15%;
}

.threeColumnLayout30 {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 32% 32% 32%;
}

.boxShadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px
}

.avatarCircle {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 15.2px;
    color: rgb(142, 142, 142);
    font-weight: 700;
    background-color: rgb(249, 249, 249);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 1px solid transparent;
    border-radius: 32px;
}

.lineHeight1 {
    line-height: 1 !important;
}

.widthFitContent {
    width: fit-content !important;
}

.lineThrough {
    text-decoration: line-through;
}

.m0 {
    margin: 0px !important;
}

.enlargedIcon {
    transform: scale(5);
}

.justifySelfCenter {
    justify-self: center !important;
}

.padding1x2x2x2rem {
    padding: 1rem 2rem 2rem 2rem !important;
}

.color60_60_60 {
    color: rgb(60, 60, 60);
}

.borderRadius15 {
    border-radius: 15px;
}
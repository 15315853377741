.footerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgb(255, 255, 255);
    min-height: 30px;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    align-items: center;
    border-top: 1px solid rgb(212, 212, 212);
}
.sidebarContainer {
    border-right: 1px solid rgb(236, 236, 236);
    padding: 2px 16px 16px;
    position: fixed;
    left: 0;
    top: 80px;
    z-index: 1;
    background-color: #fff;
    width: 13.66%;
    height: 100%;
}